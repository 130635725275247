import React, { useState } from 'react';
import './Header.css';
import { ReactComponent as Logo } from '../../../../Assets/logo.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import ChatBar from './ChatBar/ChatBar';
import { useMyContext } from '../../../../context/ProjectProvider';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { changeLanguage, currentProject } = useMyContext();

  const [showDropdown, setShowDropdown] = useState(false);
  

  const [isMessagesCollapsed, setIsMessagesCollapsed] = useState(true);

  const handleHeaderLeftClick = () => {
    if (location.pathname !== '/') {
      navigate('/');
    } else {
      window.scrollTo(0, 0);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const mappedCountry = (countryCode) => {
    return countryCode === 'it' ? 'IT' : countryCode === 'en' ? 'US' : '';
  }

  //const [selectedCountry, setSelectedCountry] = useState(mappedCountry(currentProject.language));

  const handleCountrySelect = (countryCode) => {
    changeLanguage(countryCode);
    //const mappedCountry = countryCode === 'it' ? 'IT' : countryCode === 'en' ? 'US' : '';
    //setSelectedCountry(mappedCountry(countryCode));
    setShowDropdown(false);
  };

  const CountrySelector = () => (
    <div className="country-selector">
      <button className="country-button" onClick={toggleDropdown} title="Country of interest">
        {mappedCountry(currentProject.language)}
      </button>
      {showDropdown && (
        <div className="country-dropdown">
          <button onClick={() => handleCountrySelect('it')}>IT</button>
          <button onClick={() => handleCountrySelect('en')}>US</button>
        </div>
      )}
    </div>
  );

  return (
    <div
      className={`header-container ${
        isMessagesCollapsed ? 'messages-collapsed' : 'messages-expanded'
      }`}
    >
      <header className="homepage-header">
        <div
          className="header-left"
          style={{ cursor: 'pointer' }}
          onClick={handleHeaderLeftClick}
        >
          <Logo className="header-logo" />
          <div className="header-title" translate="no">Deeplayered</div>
        </div>
        <div className="header-chatbar">
          <ChatBar
            isMessagesCollapsed={isMessagesCollapsed}
            setIsMessagesCollapsed={setIsMessagesCollapsed}
          />
        </div>
        <CountrySelector /> {/* Llamamos al componente interno */}
      </header>
    </div>
  );
}

export default Header;
