import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { useMyContext } from '../../context/ProjectProvider';

function Footer() {
  const { t } = useMyContext();
  return (
    <footer className="homepage-footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>{t('Company')}</h4>
          <ul>
            <li><Link to="/about">{t('Aboutus')}</Link></li>
            <li><Link to="/search-engine-reference">{t('Documentation')}</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>{t('Termspolicies')}</h4>
          <ul>
            <li><Link to="/terms-of-use">{t('Termsofuse')}</Link></li>
            <li><Link to="/privacy-policy">{t('Privacypolicy')}</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>{t('Contactus')}</h4>
          <p>contact@deeplayered.ai</p>
          {/* Botón de LinkedIn */}
          <a
            href="https://www.linkedin.com/company/102903646"
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-icon"
          >
            <i className="bi bi-linkedin"></i>
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; 2023 - {new Date().getFullYear()} Deeplayered LLC. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;