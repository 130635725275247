import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

export const ProjectContext = createContext();

export const useMyContext = () => useContext(ProjectContext);

export const ProjectProvider = ({ children }) => {

    const { t, i18n } = useTranslation();

    const changeLanguage = async (lang) => {
      
        try {
          
          // Se hace la llamada para obtener la nueva info de prompts
          const { sub_param, default_model, language } = await getUser(lang);
          
          // También cargamos un nuevo proyecto, etc.
          await loadNewProject(sub_param, default_model, language);
      
        } catch (error) {
          console.error("Error changing language:", error);
        } 
      };
      

    const [currentProject, setCurrentProject] = useState({
        screenMessage: "",
        displayedProducts: [],
        messages: [],
        conversationId: "",
        id: null,
        key: null,
        lastModelUsed: null,
        language: null,
        sub: null,
        projectName: "New Chat",
        canModifyDisplayedProducts: true
    })

    const [loading, setLoading] = useState(false);
    const [promptLoading, setpromptLoading] = useState(false)
    const [currentSub, setCurrentSub] = useState(null);
    const [defaultModel, setDefaultModel] = useState("");
    const [initialized, setInitialized] = useState(false);
    const [prebuiltPrompts, setPrebuiltPrompts] = useState([]);
    const [promptToSend, setPromptToSend] = useState('');

    

    useEffect(() => {
        if (process.env.REACT_APP_NODE_ENV !== "production") {
            console.log("currentProject: ", currentProject)
        }
        
    }, [currentProject])


    const getUser = useCallback(async (language_should_be_param) => {
        // Aquí forzamos el spinner a aparecer
        setpromptLoading(true);
        //this would be the flow to authenticate
        //also retrieves key info from user like default language/store
        //if language_should_be_parm is null backed will assign a language, if not, will override default for user to whatever is
        try {
            const response = await axios.post(process.env.REACT_APP_NO_STREAM_API, { call: 0, language: language_should_be_param });
            //console.log("response data sub: ", response)
            setCurrentSub(response.data.sub);
            setPrebuiltPrompts(response.data.prebuilt_prompts);
            setDefaultModel(response.data.default_model);
            i18n.changeLanguage(response.data.language);
            console.log("Language changed:", response.data.language);
            return {
                sub_param: response.data.sub,
                default_model: response.data.default_model,
                language: response.data.language
            };
        } catch (error) {
            if (process.env.REACT_APP_NODE_ENV !== "production") {
                console.log("error retrieving user: ", error)
            }
        } finally {
            // Apagamos el spinner, pase lo que pase
            setpromptLoading(false);
          }
    }, [i18n]);


    const fetchProjectDetails = async (projectId) => {
        setLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_NO_STREAM_API, {
                call: 2,
                conversation_id: projectId,
                sub: currentSub
            });

            const conversationHistory = response.data.conversation_history;

            setCurrentProject({
                screenMessage: response.data.screen_message,
                displayedProducts: response.data.displayed_products,
                messages: conversationHistory,
                conversationId: projectId,
                key: response.data.key,
                lastModelUsed: response.data.last_model_used,
                language: i18n.language,
                sub: response.data.sub,
                projectName: response.data.project_name,
                canModifyDisplayedProducts: currentProject.canModifyDisplayedProducts
            })

            setDefaultModel(response.data.last_model_used)
        } catch (error) {
            if (process.env.REACT_APP_NODE_ENV !== "production") {
                console.log("error fetching project details: ", error)
            }
        } 
    }

    const loadNewProject = useCallback(async (sub_param, default_model, language) => {
        //console.log("loadNewProject called. default_model is: ", default_model)
        try {
            const newCurrentProject = {
                screenMessage: "",
                displayedProducts: [],
                messages: [],
                conversationId: uuidv4(),
                key: null,
                lastModelUsed: default_model,
                language: language,
                sub: sub_param,
                projectName: "New Chat!",
                canModifyDisplayedProducts: true
            };

            setCurrentProject(newCurrentProject);

        } catch (error) {
            if (process.env.REACT_APP_NODE_ENV !== 'production') {
                console.error("error loading new project: ", error)
            }
        }
    }, []);

    useEffect(() => {
        const initialize = async () => {
            const { sub_param, default_model, language } = await getUser(null)


            await loadNewProject(sub_param, default_model, language)

            setInitialized(true);
        };

        if (!initialized) {
            initialize();
        }
    }, [initialized, getUser, loadNewProject]);

    const saveProject = async (project, triggerLoader = false, cancelRun=false) => {
        if (triggerLoader) {
          setLoading(true);
        }
      
        try {
          await axios.post(process.env.REACT_APP_NO_STREAM_API, {
            call: 4,
            sub: project.sub,
            project_name: 'default project name',
            project,
            cancelRun
          });
        } catch (error) {
            if (process.env.REACT_APP_NODE_ENV !== 'production') {
                console.error('Error saving project: ', error);
            }
        } finally {
          if (triggerLoader) {
            setLoading(false);
          }
        }
    };

    return (
        <ProjectContext.Provider value={{
            currentSub,
            currentProject,
            changeLanguage,
            defaultModel,
            //fetchProjects,
            saveProject,
            setCurrentProject,
            fetchProjectDetails,
            loading,
            prebuiltPrompts,
            promptToSend, 
            setPromptToSend,
            t,
            promptLoading
        }}>
            {children}
        </ProjectContext.Provider>

    );


};