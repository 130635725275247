import React from 'react';
import './i18n';
import Home from './Rutas/Home/Home';
import About from './Rutas/About/About';
import PrivacyPolicy from './Rutas/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './Rutas/TermsOfUse/TermsOfUse';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ProjectProvider } from './context/ProjectProvider';
import SearchEngineReference from './Rutas/SearchEngineReference/SearchEngineReference';

function App() {
  return (
    <Router>
      <ProjectProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/search-engine-reference" element={<SearchEngineReference />} />
        </Routes>
      </ProjectProvider>
    </Router>
  );
}

export default App;
