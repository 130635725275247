// src/i18n.js o donde estés configurando i18next
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

// Asegúrate de que la configuración esté correctamente escrita
i18next
  .use(initReactI18next) // Necesario para react-i18next
  .init({
    resources: {
      en: {
        translation: {
          welcome: "Size and find the perfect equipment using AI",
          //Footer
          Company: "Company",
          Aboutus: "About us",
          Documentation: "Documentation",
          Termspolicies: "Terms & policies",
          Termsofuse: "Terms of use",
          Privacypolicy: "Privacy policy",
          Contactus: "Contact us",
          //documentacion
          Introduction: "Introduction",
          SearchEngines: "Search Engines",
          WelcometotheDeepLayered: "Welcome to the DeepLayered Shop Equipment Finder Documentation!",
          TheDeepLayeredShopis: "The DeepLayered Shop is powered by an intelligent AI bot that simplifies the process of selecting the perfect equipment for your needs. At the core of this system are equipment sizing and finding tools, referred to as search engines. These engines are specialized functions designed to process your unique requirements, calculate precise equipment specifications, and return the best-matched products available in our store.",
          CoreConcepts: "Core Concepts",
          OpenSidebar: "Open Sidebar",
          CloseSidebar: "Close Sidebar",
          CentrifugalPump: "Centrifugal Pump",
          SearchEnginesasFunctions: "Search Engines as Functions:",
          Thinkofeachsearchengine: "Think of each search engine as a practical tool, tailored to help you size and select equipment for specific tasks.",
          DynamicQueries: "Dynamic Queries:",
          Yousimplyprovideyourneeds: "You simply provide your needs, and the AI bot translates them into actionable queries that pinpoint the best options for you.",
          StandardizedResponses: "Standardized Responses",
          Allsearchenginesreturn: "All search engines return computed specifications for the equipment and a curated list of real matching products.",
          WhoIsThisFor: "Who Is This For?",
          Professionalsandhobbyistslooking: "Professionals and hobbyists looking for equipment tailored to specific requirements.",
          WhatYoullFindHere: "What You'll Find Here",
          SearchEngines1: "Search Engines:",
          Learnabouttheparameters: "Learn about the parameters, functionality, and responses of each search engine.",
          Searchenginesformthecore: "Search engines form the core of the AI bot, each designed to address specific query types with precision. Below is an overview of available search engines, their parameters, and their response formats.",
          CentrifugalPump1: "1. Centrifugal Pump",
          Description: "Description:",
          Computestheadditionalhead: "Computes the additional head (in feet) required to meet system flow requirements and identifies suitable centrifugal pumps. The function uses the mechanical energy balance equation (in feet) and accounts for friction losses, elevation changes, velocity differences, and system resistance.",
          Parameters: "Parameters:",
          Parameter: "Parameter",
          Type: "Type",
          Required: "Required",
          text: "text",
          Yes: "Yes",
          freeTextDescription: "Non-deterministic, semantic criteria",
          flowrate: "flowrate_us_gpm",
          number: "number",
          flowrate_us_gpmDescription: "Flowrate in US Gallons per minute",
          fluid_density: "fluid_density_lbs_per_cubic_foot",
          fluid_densityDescription: "Fluid density in pounds per cubic foot",
          fluid_absolute_viscosity: "fluid_absolute_viscosity_cp",
          fluid_absolute_viscosityDescription: "Fluid absolute viscosity in centipoise",
          fluid_vapor_pressure: "fluid_vapor_pressure_psi",
          fluid_vapor_pressureDescription: "Fluid vapor pressure in pounds per square inch. Water at 60 Farenheit has a vapor pressure of 0.256 psi",
          suction_reservoir_pressure: "suction_reservoir_pressure_psia",
          suction_reservoir_pressureDescription: "P1 as per Figure 1.1. Absolute pressure in pounds per square inch. This would be the atmospheric pressure if fluid is not contained in a pressurized reservoir",
          suction_static_head: "suction_static_head_feet",
          suction_static_headDescription: "h1 as per Figure 1.1. The vertical distance between the fluid level in the suction reservoir and the pump centerline, accounting for elevation above or below the pump. Positive if above the pump, negative if bellow the pump",
          suction_valves_and_fittings: "suction_valves_and_fittings",
          list: "list",
          suction_valves_and_fittingsDescription: "A1 as per Figure 1.1. Accessories present in the suction pipe. Possibilities are:",
          accessory_type: "accessory_type",
          number_of_units: "number_of_units",
          numberofunits: "number of units",
          Anyofthefollowingoptions: "Any of the following options:",

          Computations: "Computations:",
          Thefollowingcomputationsare: "The following computations are performed using the provided parameter values. The computations primarily adhere to industry-standard principles as outlined in the Hydraulic Institute's documentation. For further details, please refer to the official Hydraulic Institute resources",
          Figure1: "Figure 1.1: Illustration of variables used in Centrifugal Pump Calculations",
          Parameterabreviations: "Parameter abreviations:",
          Equations: "Equations:",
          PumpHeadand: "1. Pump Head and equivalent pressure:",
          Hatpumpsuctionflange: "1.a. H at pump suction flange:",
          Hatpumpdischargeflange: "1.b. H at pump discharge flange:",
          PumpHead: "1.c. Pump Head:",
          BreakHorsepower: "2. Break Horsepower:",
          NetPositiveSuctionHeadavailable: "3. Net Positive Suction Head available:",
          ResponseFormat: "Response Format:",
          WhenyouusetheDeepLayeredShops: "When you use the DeepLayered Shop's equipment sizing and finding tools, the results are presented in a clear and actionable format. These values are computed from the specific parameters you provide, ensuring the recommended equipment matches your operational needs. Here's what you can expect in your results:",

        },
      },
      it: {
        translation: {
          welcome: "Dimensiona e trova l'attrezzatura perfetta utilizzando l'IA",
          // Footer
          Company: "Azienda",
          Aboutus: "Chi siamo",
          Documentation: "Documentazione",
          Termspolicies: "Termini e politiche",
          Termsofuse: "Termini di utilizzo",
          Privacypolicy: "Informativa sulla privacy",
          Contactus: "Contattaci",
          //documentacion
          Introduction: "Introduzione",
          SearchEngines: "Motori di ricerca",
          WelcometotheDeepLayered: "Benvenuto nella documentazione del 'DeepLayered Shop Equipment Finder'!",
          TheDeepLayeredShopis: "Il DeepLayered Shop è alimentato da un bot intelligente basato sull'IA che semplifica il processo di selezione dell'attrezzatura perfetta per le tue esigenze. Al centro di questo sistema ci sono gli strumenti per il dimensionamento e la ricerca delle attrezzature, definiti come motori di ricerca. Questi motori sono funzioni specializzate progettate per elaborare le tue esigenze specifiche, calcolare le specifiche precise delle attrezzature e restituire i prodotti più adatti disponibili nel nostro negozio.",
          CoreConcepts: "Concetti fondamentali",
          OpenSidebar: "Apri barra laterale",
          CloseSidebar: "Chiudi barra laterale",
          CentrifugalPump: "Pompa centrifuga",
          SearchEnginesasFunctions: "Motori di ricerca come funzioni:",
          Thinkofeachsearchengine: "Pensa a ogni motore di ricerca come a uno strumento pratico, progettato per aiutarti a dimensionare e selezionare l'attrezzatura per compiti specifici.",
          DynamicQueries: "Query dinamiche:",
          Yousimplyprovideyourneeds: "Basta fornire le tue esigenze e il bot IA le traduce in query operative che individuano le migliori opzioni per te.",
          StandardizedResponses: "Risposte standardizzate",
          Allsearchenginesreturn: "Tutti i motori di ricerca restituiscono specifiche calcolate per l'attrezzatura e una lista selezionata di prodotti reali corrispondenti.",
          WhoIsThisFor: "Per chi è pensato?",
          Professionalsandhobbyistslooking: "Professionisti e appassionati in cerca di attrezzature su misura per requisiti specifici.",
          WhatYoullFindHere: "Cosa troverai qui",
          SearchEngines1: "Motori di ricerca:",
          Learnabouttheparameters: "Scopri i parametri, le funzionalità e le risposte di ogni motore di ricerca.",
          Searchenginesformthecore: "I motori di ricerca costituiscono il cuore del bot IA, ciascuno progettato per gestire con precisione tipi specifici di query. Di seguito è riportata una panoramica dei motori di ricerca disponibili, dei loro parametri e dei formati di risposta.",
          CentrifugalPump1: "1. Pompa Centrifuga",
          Description: "Descrizione:",
          Computestheadditionalhead: "Calcola la prevalenza aggiuntiva (in metri) necessaria per soddisfare i requisiti di flusso del sistema e identifica le pompe centrifughe adatte. La funzione utilizza l'equazione di bilancio dell'energia meccanica (in metri) e tiene conto delle perdite per attrito, delle variazioni di elevazione, delle differenze di velocità e della resistenza del sistema.",
          Parameters: "Parametri:",
          Parameter: "Parametro",
          Type: "Tipo",
          Required: "Obbligatorio",
          text: "testo",
          Yes: "Sì",
          freeTextDescription: "Criteri semantici non deterministici",
          flowrate: "flowrate_us_gpm",
          number: "numero",
          flowrate_us_gpmDescription: "Portata in galloni USA al minuto",
          fluid_density: "densita_fluido_kg_per_metro_cubo",
          fluid_densityDescription: "Densità del fluido in libbre per metro cubo",
          fluid_absolute_viscosity: "viscosità_assoluta_fluido_cp",
          fluid_absolute_viscosityDescription: "Viscosità assoluta del fluido in centipoise",
          fluid_vapor_pressure: "pressione_vapore_fluido_bar",
          fluid_vapor_pressureDescription: "",
          suction_reservoir_pressure: "pressione_serbatoio_di_aspirazione_bar",
          suction_reservoir_pressureDescription: "P1 come indicato nella Figura 1.1. Pressione assoluta in libbre per bar. Questa sarebbe la pressione atmosferica se il fluido non fosse contenuto in un serbatoio pressurizzato",
          suction_static_head: "altezza_statica_aspirazione_metro",
          suction_static_headDescription: "h1 come indicato nella Figura 1.1. La distanza verticale tra il livello del fluido nel serbatoio di aspirazione e la linea centrale della pompa, tenendo conto dell'elevazione sopra o sotto la pompa. Positiva se sopra la pompa, negativa se sotto la pompa",
          suction_valves_and_fittings: "valvole_e_raccordi_aspirazione",
          list: "elenco",
          suction_valves_and_fittingsDescription: "A1 come indicato nella Figura 1.1. Accessori presenti nel tubo di aspirazione. Possibilità:",
          accessory_type: "tipo_accessorio",
          number_of_units: "numero_di_unità",
          numberofunits: "numbero di unità",
          Anyofthefollowingoptions: "Qualsiasi delle seguenti opzioni:",

          
          Computations: "Calcoli:",
          Thefollowingcomputationsare: "I seguenti calcoli vengono eseguiti utilizzando i valori dei parametri forniti. I calcoli seguono principalmente i principi standard del settore, come indicato nella documentazione dell'Hydraulic Institute. Per ulteriori dettagli, si prega di fare riferimento alle risorse ufficiali dell'Hydraulic Institute",
          Figure1: "Figura 1.1: Illustrazione delle variabili utilizzate nei calcoli per le pompe centrifughe",
          Parameterabreviations: "Abbreviazioni dei parametri:",
          Equations: "Equazioni:",
          PumpHeadand: "1. Prevalenza della pompa e pressione equivalente:",
          Hatpumpsuctionflange: "1.a. H al flangia di aspirazione della pompa:",
          Hatpumpdischargeflange: "1.b. H al flangia di mandata della pompa:",
          PumpHead: "1.c. Prevalenza della pompa:",
          BreakHorsepower: "2. Potenza effettiva (BHP - Break Horsepower):",
          NetPositiveSuctionHeadavailable: "3. Altezza netta positiva di aspirazione disponibile (NPSH disponibile):",
          ResponseFormat: "Formato di risposta:",
          WhenyouusetheDeepLayeredShops: "Quando utilizzi gli strumenti di dimensionamento e ricerca delle attrezzature del DeepLayered Shop, i risultati vengono presentati in un formato chiaro e operativo. Questi valori sono calcolati a partire dai parametri specifici che fornisci, garantendo che l'attrezzatura raccomandata soddisfi le tue esigenze operative. Ecco cosa puoi aspettarti dai tuoi risultati:",

        },
      },
    },
    lng: 'en', // Idioma predeterminado
    fallbackLng: 'en', // Idioma de respaldo
    interpolation: {
      escapeValue: false, // No es necesario con React
    },
  });

export default i18next;
