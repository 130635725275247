// DisplayedProducts.js
import React from 'react';
import './DisplayedProducts.css';
import { useMyContext } from '../../../../../context/ProjectProvider';

function DisplayedProducts() {
    const { currentProject } = useMyContext();

    const products = currentProject?.displayedProducts || [];

    //console.log("screenMessage: ", currentProject.screenMessage)

    if (products.length === 0 && currentProject.screenMessage==="") {
        return null;
    }

    const handleProductClick = (link) => {
        if (link) {
            window.open(link, "_blank"); // Open the link in a new tab
        } else {
            alert("No hay enlace disponible para este producto.");
        }
    };
    
    return (
        <div className="displayed-products-container">
            <h2 className="screen-message">
            {currentProject.screenMessage}
            </h2>
            <ul className="product-list">
                {products.map((product, index) => (
                    <li
                        key={index}
                        className="product-item"
                        onClick={() => handleProductClick(product.Link)}
                        style={{ cursor: product.Link ? 'pointer' : 'default' }}
                    >
                        {/* AHORA LA IMAGEN PRIMERO */}
                        <div className="product-image">
                            <img
                                src={product.Image || 'https://via.placeholder.com/150'}
                                alt={product.Name}
                            />
                        </div>

                        {/* LUEGO EL TÍTULO */}
                        <h3 className="product-title">{product.Name}</h3>

                        {/* Y FINALMENTE LOS DETALLES */}
                        <div className="product-details">
                        {Object.entries(product).map(([key, value]) => {
                            // Evita renderizar "Name", "Image" y "Link"
                            if (key === 'Name' || key === 'Image' || key === 'Link' || key === 'requested') return null;

                            // Si la clave es "price", renderizar solo el valor y con clase especial
                            if (key.toLowerCase() === 'price') {
                                return (
                                    <p key={key} className="price-container">
                                        <span className="product-price">{value}</span>
                                    </p>
                                );
                            }
                            return (
                                <p key={key}>
                                    <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
                                </p>
                            );
                        })}
                    </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default DisplayedProducts;
