import React from 'react';
import './Body.css';
import DisplayedProducts from './DisplayedProducts/DisplayedProducts';
import { useMyContext } from '../../../../context/ProjectProvider';

function Body() {

  const { setPromptToSend, currentProject } = useMyContext();

  const handlePromptClick = (prompt) => {
    setPromptToSend(prompt);
  };

  return (
    <div className="body-container">
      {currentProject.displayedProducts.length === 0 && currentProject.screenMessage==="" && (
        <TextTutorial />
      )}
      {currentProject.displayedProducts.length === 0 && currentProject.screenMessage==="" && (
        <PromptsEg onPromptClick={handlePromptClick} />
      )}
      <DisplayedProducts />
      
    </div>
  );
}

// Definimos el componente PromptsEg
function PromptsEg({ onPromptClick }) {
  const { prebuiltPrompts, promptLoading } = useMyContext();

  // Si estamos 'loading' o la lista de prompts está vacía, mostramos el spinner
  if (promptLoading) {
    return (
      <div className="spinner-container">
        <div className="spinner-border text-secondary" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    );
  }

  // Caso contrario, renderizamos normalmente los prompts
  return (
    <div className="prompts-eg-container">
      {prebuiltPrompts.map((prompt, index) => (
        <button
          key={index}
          className="prompt-button"
          onClick={() => onPromptClick(prompt)}
        >
          {prompt}
        </button>
      ))}
    </div>
  );
}

// Definimos el componente TextTutorial
function TextTutorial() {
  const { t } = useMyContext();
  return (
    <div className="text-tutorial">
      <h2>{t('welcome')}</h2>
    </div>
  );
}

export default Body;
